/* Pixel to rem */
@function rem($px) {
  @return ($px / $rem-base) + rem;
}

@mixin small() {
  @media screen and (max-width: rem($small)) {
    @content;
  }
}

@mixin medium() {
  @media screen and (min-width: rem($small)) and (max-width: rem($large)) {
    @content;
  }
}

@mixin large() {
  @media screen and (min-width: rem($large)) {
    @content;
  }
}

@mixin lesser-than-large() {
  @media screen and (max-width: rem($large)) {
    @content;
  }
}
