@import "../_styles/index.module.scss";

@font-face {
  font-family: "Proxima Nova";
  src: url("../../assets/fonts/Proxima-Nova-Regular.ttf");
}

.text-field {
  border-radius: rem(4px);
  border-style: solid;
  box-sizing: border-box;
  padding: rem(12px) 0 rem(12px) rem(16px);
  border: rem(1.5px) solid $secondary-middle-blue;
  width: 100%;
  font-family: "Proxima Nova";
  font-size: rem(16px);
  line-height: rem(24px);

  &::placeholder {
    color: $primary-navy;
    opacity: 1;
  }
  
  &:focus {
    outline: none;
    border: rem(1.5px) solid $accents-hover-blue;
  }
  
  &-disabled::placeholder { opacity: 0.66; }

  &-error { 
    border-color: $error;
    &:focus { border-color: $error; }
  }
}
