@import "../../new-lib/_styles/index.module.scss";

.container {
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include mobile  { padding: 0 rem(24px); }
}

// FORM
.form-container {
  display: flex;
  flex-direction: column;
  background-color: $secondary-barbel-blue;
  width: rem(744px);
  align-self: center;
  margin-bottom: rem(112px);
  border-radius: rem(8px);
  margin-top: rem(56px);
  height: rem(908px);

  @include mobile {
    height: rem(890px);
    margin: rem(104px) 0 rem(80px);
    width: rem(342px);
    align-self: center;
  }
}

.error-form {
  @extend .form-container;
  height: rem(950px);
}

.content-container {
  display: flex;
  flex-direction: column;
  width: rem(411px);
  height: rem(750px);
  align-self: center;
  padding-top: rem(56px);

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: rem(736px);
    width: rem(310px);
    padding-top: rem(32px);
  }
}

.listing-textfield {
  width: rem(408px);
  height: rem(336px);
}

.icon-text {
  text-transform: uppercase;
  color: $primary-navy;
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-style: normal;
  font-size: rem(15px);
  line-height: rem(16px);
}

.icon {
  width: rem(270px);
  @include mobile {
    width: rem(125px);
  }
}


.button-container {
  align-self: center;
  width: rem(411px);
  display: flex;
  flex-direction: row;
  margin-top: rem(32px);
  margin-bottom: rem(56px);

  @include mobile {
    flex-direction: column;
    width: 310px;
    margin-bottom: rem(32px);
  }
}

.primary {
  width: 256px;
  margin-left: rem(32px);
  height: 48px;
  color: $primary-white;
}


.info-checkbox {
  align-self: center;

  @include mobile {
    width: rem(310px);
  }
}

.user-region-care-unit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: rem(128px);
  width: 100%;
  margin-bottom: rem(24px);

  @include desktop {
    margin-bottom: rem(40px);
  }
}
