@import '../_styles/variables.module.scss';

.root {
  background-color: $white !important;
  height: 60px;
  @media only screen and (min-width: $breakpointTablet)  {
    height: 80px;
  }
  justify-items: center;
  justify-content: center;
}

.toolbar {
  display: flex;
  flex-grow: 1;
}

.row {
  display: flex;
  flex-grow: 1;
}

.leftSlot {
  display: flex;
  flex-shrink: 1;
}

.centerSlot {
  display: flex;
  flex: 5;
  justify-content: space-around;

}

.rightSlot {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.topRightMenu {
  width: 300px;
}
