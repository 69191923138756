//Re-design
//Design Colors: https://www.figma.com/file/F6l540MGby7bS0tRds9Eaq/Design-System?node-id=2%3A4
$background: #F3F3F3;
$secondary-bg: #FBFBFB;

$primary-navy: #0D3050;
$primary-navy-disabled: #9FAEBD;
$primary-white: #FFFFFF;

$secondary-middle-blue: #D1DFF1;
$secondary-light-blue: #EEF2F8;
$secondary-barbel-blue: #F0F4FA;
$secondary-middle-gray: #EDEBEF;
$secondary-light-gray: #F9F8FA;
$secondary-middle-green: #EDF1E5;
$secondary-light-green: #F9FAF6;
$secondary-light-green: #F9FAF6;
$secondary-coral-66: #EE9A87;


$extra-middle-salmon: #FCE7DE;
$extra-light-salmon: #FEF3EE;
$extra-middle-corn: #FEF5E3;
$extra-light-corn: #FFFAF1;

$success: #40806A;
$error: #A80000;
$error-hover: #B01717;
$error-focus: #CF0000;
$error-pressed: #850000;
$error-disabled: #A68282;

$disabled-text: rgba(13, 48, 80, 0.66);

$base-black: #333333;
$base-white: #F9FAFB;
$base-bg: #F3F3F3;
$base-secodary-bg: #FBFBFB;
$base-grey: #949494;
$base-grey-frame: #A6A6A6;

$accents-hover-blue: #1C5DAC;
$accents-focused: #729FD4;
$accents-pressed: #275284;


//TODO: Remove when re-design project is complete. Font-size of root element needs to be set to keep the size of rem()
$html-font-size: 16px;
@function stripUnit($value) {
 
    @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}


//Breakpoints
$mobile-max-width-px: 1200;
$desktop-min-width-px: 1200;

$mobile-min-width: 0px;
$mobile-max-width: rem($mobile-max-width-px);
$desktop-min-width: rem($desktop-min-width-px);

@mixin mobile {
    @media (min-width: #{$mobile-min-width}) and (max-width: #{$mobile-max-width}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-min-width}) {
        @content;
    }
}


:export {
    primaryNavy: $primary-navy;
    primaryWhite: $primary-white;
    
    secondaryMiddleBlue: $secondary-middle-blue;
    secondaryLightBlue: $secondary-light-blue;   
    secondaryMiddleGray: $secondary-middle-gray;
    secondaryLightGray: $secondary-light-gray;
    secondaryMiddleGreen: $secondary-middle-green;
    secondaryLightGreen: $secondary-light-green;

    extraMiddleSalmon: $extra-middle-salmon;
    extraLightSalmon: $extra-light-salmon;
    extraMiddleCorn: $extra-middle-corn;
    extraLightCorn: $extra-light-corn;

    success: $success;
    error: $error;
    disabled: $disabled-text;
    accentsHoverBlue: $accents-hover-blue;
    baseBlack: $base-black;
    baseWhite: $base-white;
    baseBg: $base-bg;
    base-secondary-bg: $base-secodary-bg;
    base-grey: $base-grey;
    baseGreyFrame: $base-grey-frame;
    mobileMinWidth: $mobile-min-width;
    mobileMaxWidth: $mobile-max-width;
    desktopMinWidth: $desktop-min-width;
    mobileMaxWidthPx: $mobile-max-width-px;
    desktopMinWidthPx: $desktop-min-width-px;
}
