@import "../_styles/index.module.scss";


@font-face {
    font-family: 'Proxima Nova';
    src: url("../../assets/fonts/Proxima-Nova-Regular.ttf");
}


.container {
    flex: 1;
    display: "inline-block";
}

.checkbox {
    flex: 1;
    align-self: center;
    position: relative;
}

/* Customize the label (the container) */
.form-control {
    display: grid;
    grid-template-columns: rem(8) auto;
    column-gap: rem(32);
}

.tick {
    top: rem(1);
    position: absolute;
    margin-left: rem(7);
    margin-top: rem(6);
    z-index: 0;
    pointer-events: none;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */

    /* Not removed via appearance */
    font: inherit;
    border-radius: 4px;
    display: grid;
    place-content: center;

    @include mobile {
        width: rem(32);
        height: rem(32);
    }

    @include desktop {
        width: rem(24);
        height: rem(24);
    }

    flex: 1;
    align-self: center;

}


.default-box {
    border: rem(1) solid $secondary-middle-blue;
    background-color: $primary-white;
    cursor: pointer;
}

.error-box {
    border: rem(1) solid $error;
    background-color: $primary-white;
    cursor: pointer;
}

.disabled-box {
    border: rem(1) solid $base-grey;
    background-color: $base-bg;
    cursor: default;
}

.disabled-box-dummy {
    border: rem(0.5) solid $base-bg;
    background-color: $base-bg;
    cursor: default;
}


.label {
    flex: 1;
    align-self: center;
    font-family: "Proxima Nova";
    font-size: rem(16);
    line-height: rem(24);

    @include mobile  { margin: 0 rem(10px); }
    @include desktop { margin: 0 rem(2px); }
}

.default-label-color {
    color: $primary-navy;
}

.error-label-color {
    color: $error;
}

.disabled-label-color {
    @extend .default-label-color;
    opacity: 0.66;
}

.error-helper {
    @extend .error-label-color;
    display: block;
    text-align: left;
    font-family: "Proxima Nova";
    font-size: rem(12);
    line-height: rem(16);
}


input[type="checkbox"]:checked::before {
    transform: scale(1);
    flex: 1;
    align-self: center;
}


.form-control+.form-control {
    margin-top: 1em;
}
