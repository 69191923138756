@import "../_styles/variables.module.scss";


.input {
    box-sizing: border-box;
    height: 54px;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-radius: 27px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-family: AvenirLTStdHeavy !important;
    font-size: 16px;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;

    @media (min-width: $breakpointTablet) {
        width: 345px;
    }
    
}



.inputColor {
    border-color: $black;
}

.inputFocusedColor {
    color: $primary !important;
    border-color: $primary;
    background-color: $grayBright;
}

.inputDisabledColor {
    border-color: $gray;
}

.inputErrorColor {
    color: $alert !important;
    border-color: $alert;
}

.select {
    padding-left: 24px !important;
    text-align: center !important;
    background-color: transparent !important;
}

.label {
    display:none;
    visibility:hidden;
}