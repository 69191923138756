@import "../../../lib/_styles/variables.module";


.content {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 100%;

  @media only screen and (max-width: $breakpointTablet) {
    background-color: transparent;
    height: 0;
  }
}

.centerChoice {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;

  max-width: 768px;
  min-width: 310px;
  @media only screen and (min-width: $breakpointTablet) {
    min-width: 600px;
  }
  background-color: $white;
  margin-top: 25px;
  @media only screen and (max-width: $breakpointTablet) {
    margin-top: unset;
  }

  .centerInputs {
    flex: 1;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    align-items: center;
    margin: 15px 15px;
    width: 100%;
  }


  .contentContainer {
    background-color: $white;
    min-width: 380px;
  }

  .checkBoxWrapper {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    flex-direction: row;
  }

  .settingsDescriptionWrapper {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: center;
    flex-direction: row;

    white-space: pre-wrap;
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 35px;
    text-align: center;
    .text {
      font-size: 15px;
      white-space: pre-wrap;
    }
    .blueLinkColor {
      color: $blue;
      text-decoration: none;
    }
  }

  .fancySpacerContainer {
    min-width: 226px;
    overflow: hidden;
    margin: 0px auto 0px;
  }



  .fancy {
    line-height: 0.5;
    text-align: center;
    width: 100%;
  }
  .fancy span {
    display: inline-block;
    position: relative;
  }
  .fancy span:before,
  .fancy span:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid $black;
    min-width: 120px;
  }
  .fancy span:before {
    right: 100%;
    margin-right: 15px;
  }
  .fancy span:after {
    left: 100%;
    margin-left: 15px;
  }

}
