@import '../_styles/variables.module.scss';

.root {
    background-color: $primary !important;
    height: 60px;
    justify-items: center;
    justify-content: center;
}

.toolbar {
    display: flex;
    flex-grow: 1;
}

.row {
    display: flex;
    flex-grow: 1;
}

.leftSlot {
    display: flex;
    flex: 3;
    height: 100%;
    margin-top: 14px;
    margin-left: 4px;
    
    
}

.rightSlot {
    display: flex;
    flex-shrink: 1;
    justify-content: flex-end;
}

.drawerCloseButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: -10px;
}

.drawerContainer {
    background-color: $primary !important;
    overflow-x: hidden;
}

.drawerList {
    background-color: $primary !important;
}

.drawerPrimaryActionsContainer {
    width: 300px;
    padding-left: 20px !important;
    margin-bottom: 10px;
}

.drawerSecondaryActionsContainer {
    width: 300px;
    padding-left: 20px;
    margin-top: 10px;
}

.buttonLi {
    padding-left: 16px;
}