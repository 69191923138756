@import '../../new-lib/_styles/index.module.scss';

.body {
  background-color: $background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile  { padding: rem(32px) 0 rem(80px);  }
  @include desktop { padding: rem(56px) 0 rem(104px); }
}

.back-link {
  text-decoration: none;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  gap: rem(12px);
  
  margin-bottom: rem(12px);

  // animation
  position: relative;
  padding-bottom: rem(2px);
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(2px);
    background-color: $primary-navy;
    visibility: hidden;
    transition: 120ms linear;
    transform: scaleX(0);
  }
  &:hover::after,:focus::after {
    visibility: visible;
    transform: scaleX(1);
  }
}

.card {
  background-color: $primary-white;
  border-radius: rem(16px);
  box-sizing: border-box;

  @include mobile {
    max-width: rem(342px);
    padding: rem(24px) rem(16px);
  }

  @include desktop {
    max-width: rem(408px);
    padding: rem(32px) rem(24px);
  }
}