@import "../../_styles/index.module.scss";

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(24px) rem(24px) 0;
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: rem(16px);

  @include mobile { padding: rem(16px) 0; }
  @include desktop { padding: rem(24px) 0; }
}

.otp-container {
  display: flex;
  gap: rem(14px);
  flex-direction: column;
}

.otp-error {
  background-color: $error;
  color: $primary-white;
  border-radius: rem(4px);
  padding: rem(4px);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  border-radius: rem(24px);  
}

.overlay-loader { background: rgba(211, 211, 211, 0.6); }
.overlay-success {
  background: $primary-white;
  animation: fade-in 200ms;
} @keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
