@import '../_styles/variables.module.scss';

.speechBubble {
	position: relative;
	background: #fff;
    border-radius: .4em;
    margin-top: -6px;
	-webkit-box-shadow: 0px 2px 3px 0px rgba(181,181,181,0.5); 
	box-shadow: 0px 2px 3px 0px rgba(181,181,181,0.5);
}

.speechBubble:after {
	content: '';
	position: absolute;
	top: 0;
	left: 80%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: #fff;
	border-top: 0;
	margin-left: -10px;
	margin-top: -10px;
}

.container {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
}


.closeButtonContainer {
    text-align: center;
    margin-top: 20px;
}