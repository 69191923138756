@import '../../new-lib/_styles/index.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box {
  // box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: rem(552px);
  background: $secondary-light-blue;
  border-radius: 24px;
  align-items: center;
  margin-top: rem(56px);
  margin-bottom: rem(98px);

  @include desktop { padding: rem(40px) 0;}

  @include mobile {
    display: flex;
    flex-direction: column;
    width: rem(342px);
    padding: rem(24px) 0;
  }
}

.content {
  align-items: center;
  padding-left: rem(16px);
  padding-right: rem(24px);
}

$duration: 0.5s;

.icon-spin {
  width: rem(150px);
  height: rem(150px);
  animation: spin $duration 1;
}

@keyframes spin {
  0% {
    transform: rotate(180deg);
  } 
  90% {
    transform: rotate(350deg);
  }
  80%{
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
