@import "../../new-lib/_styles/index.module.scss";

.container {
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: center;
    align-items: center;
    background-color: $background;
    padding: rem(56px) rem(24px) rem(104px);
}

.content-container {
    max-width: rem(310px);
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    align-items: center;

    @include mobile  { margin: rem(32px) rem(16px); }
    @include desktop { margin: rem(40px); }
}
