@import '../_styles/index.module.scss';

//Desktop
.desktop {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $primary-navy;
    align-items: center;
    width: 100%;

    padding: rem(80px) 0;
    &-logged-in { padding-bottom: rem(48px); }
}

.content {
    max-width: rem(1128px);
}

.column-desktop {
    display: flex;
    flex-direction: row;
    gap: rem(96px);
    height: rem(96px);
    width: rem(1128px);
    align-items: center;
}

.section-row {
    @extend .column-desktop;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
    // gap: rem(32px);
    margin-top: rem(96px);
    height: 100%;
}

.column-sections {
    list-style-type: none;
}

// Mobile 
.mobile-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $primary-navy;
    padding: rem(60px) rem(24px) rem(60px) rem(24px);
}

.mobile-container-signed-in {
    @extend .mobile-container;
    flex-direction: row;
    flex-wrap: wrap;
}


.text-div {
    margin-top: rem(50px);
}


.first-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: rem(50px);
}

.section-margin {
    margin-top: rem(30px);
}

// Text style
.text-content {
    text-decoration: none;
    color: $base-white;
    font-family: 'Proxima Nova';
    font-size: rem(14px);
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: rem(24px);
}

.link {
    text-decoration: none;
    color: $base-white;
    font-family: 'Proxima Nova';
    font-size: rem(14px);
    font-weight: 400;
    line-height: rem(24px);
    list-style-type: none;
    margin: 0px;
}

.header-desktop {
    color: $base-white;
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-size: rem(16px);
    line-height: rem(24px);
    text-transform: uppercase;
    margin: 0px;

    @include mobile {
        line-height: rem(18px);
        font-size: rem(14px);
    }
}

.links {
    list-style-type: none;
    color: $base-white;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(24px);
    padding-top: rem(24px);
    margin: 0px;

    &:hover {
        text-decoration: underline;
    }
}
