@import "../../lib/_styles/variables.module.scss";


.flow {
  display: flex;
  flex: 1;
  justify-content: center;
  word-break: keep-all;
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 520px) {
    height: calc(100% - 10px);
  }

  .spacer {
    height: 2rem;
  }
}

.respContainer {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  border-left-color: $gray;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: $gray;
  border-right-style: solid;
  border-right-width: 1px;
}

.respIframe {
  flex: 1;
  top: 0;
  width: 100%;
  height: 100%;
  border: 0;
  @media only screen and (max-width: 520px) {
    margin-top: 0;
  }

  overscroll-behavior-y: contain;
}
