@import '../../new-lib/_styles/index.module.scss';

.body {
  box-sizing: border-box;
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @include mobile  { padding: rem(32px) rem(14px) rem(80px); }
  @include desktop { padding: rem(56px) 0 rem(104px); }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile  { gap: rem(24px); }
  @include desktop { gap: rem(32px); }
}

.text-container {
  width: 100%;
  margin-top: rem(8px);

  display: flex;
  align-items: center;
}

.text-divider {
  background-color: $primary-navy;

  border: rem(1px) solid $primary-navy;
  border-radius: rem(24px);
  height: rem(14px);

  display: inline-block;
  margin: 0 rem(14px);
}
