@import "../_styles/index.module.scss";

.container {
    display: flex;
    flex-direction: column;
    
    @include mobile {
        max-width: rem(310px);
    }

    @include desktop {
        width: rem(456px);
    }
}

.div {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    width: 100%;
    min-height: rem(24px);
    position: relative;
}

.divider {
    margin-right: auto;
    width: 1px;
    height: 100%;
}

.span {
    position: absolute;
    margin-top: rem(20px);
    margin-right: rem(10px);
    pointer-events: none;
}

.dropdown {
    color: rgb(13, 48, 80);
    appearance: none;
    width: 100%;
    border: rem(1px) solid #D1DFF1;
    border-radius: rem(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: rem(16px) rem(44px) rem(16px) rem(16px);
    gap: rem(16px);
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    color: $primary-navy;
    text-overflow: ellipsis;
    background-color: $primary-white;
 
    @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: rem(16px) rem(44px) rem(16px) rem(16px);
        gap: rem(16px);
    }
}

.error-container {
    appearance: none;
    width: rem(456px);
    border: rem(1px) solid $error;
    border-radius: rem(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: rem(16px) rem(8px) rem(16px) rem(16px);
    gap: rem(16px);

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $primary-navy;
    background-color: $primary-white;

    @include mobile {
        width: rem(310px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: rem(16px);
        gap: rem(16px);
    }
}
