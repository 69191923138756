@import '../_styles/index.module.scss';

$arrow-height: 40px;
$desktop-box-width: 440px;

// Mobile
.hamburger {
    width: rem(32px);
    height: rem(16px);
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    cursor: pointer;
}
.burger {
    width: 100%;
    height: rem(2px);
    background-color: $primary-navy;
    transition: 0.2s linear;
    position: relative;
    transform-origin: 1px;
}

.mobile-box {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: $primary-white;
}

.header {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: rem(24px) rem(24px) rem(24px) rem(24px);
    height: rem(72px);
    border-bottom: rem(1px) solid $secondary-middle-blue;
}

.padding {
    padding: rem(24px);
}

.close-button {
    height: rem(40px);
    border-radius: 50%;
    border-color: transparent;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        border: rem(2px) solid $base-grey-frame;
        background-color: $base-grey-frame;
        outline: none;
    }
}

// Desktop
.popover-container {
    position: relative;
    z-index: 99;
}
.popover-content {
    position: absolute;
    &-desktop {
        @extend .popover-content;
        top: calc(8px + 100%);
        right: 50%;
    }
    &-mobile {
        @extend .popover-content;
        right: 70%;
        transform: translateY(-11%)        
    }
}

.desktop-box {
    width: rem($desktop-box-width);
    height: rem(400px);
}

.desktop {
    padding: rem(32px);
    top: rem(24px);
    width: 100%;
    background-color: $primary-white;
    border-radius: rem(24px);
    margin-left: rem(80px);
}

.arrow {
    width: rem(0px);
    height: rem(0px);
    margin-left: auto;
    border-left: rem($arrow-height) solid transparent;
    border-right: rem($arrow-height) solid transparent;
    border-bottom: rem($arrow-height) solid $primary-white;
    background-color: transparent;
}

$animation-duration: 0.4s;

// Style for both menus 
.items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(24px);
    width: 100%;
    height: 100%;
}

.circle {
    box-sizing: border-box;
    background: #EEF2F8;
    width: rem(32px);
    height: rem(32px);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.section {
    cursor: pointer;
    border-color: transparent;
    background-color: transparent;
    text-decoration: none;
    display: flex;
    padding: rem(4px);
    gap: rem(24px);
    width: 100%;
    height: 100%;
    border-radius: rem(8px);
    align-items: center;

    &:hover { background-color: $accents-hover-blue; }
    &:hover .text { color: $primary-white; }
    &:hover .circle { background-color: $accents-hover-blue; }
    &:hover .icon-default { display: none; }
    &:hover .icon-hover { display: flex; }

    &:active .icon-default { display: none; }
    &:active .icon-hover { display: flex; }

    &-pressed { background-color: $accents-pressed; }
    &-pressed .text { color: $primary-white; }
    &-pressed .circle { background-color: $accents-pressed; }
    &-pressed .icon-default { display: none; }
    &-pressed .icon-hover { display: flex; }

    &:focus { background-color: $accents-pressed; outline: none; }
    &:focus .text { color: $primary-white; }
    &:focus .circle { background-color: $accents-pressed; }
    &:focus .icon-default { display: none; }
    &:focus .icon-hover { display: flex; }
}

.button {
    cursor: pointer;
    border-color: transparent;
    background-color: transparent;
    text-decoration: none;
    display: flex;
    padding: rem(4px);
    gap: rem(24px);
    width: 100%;
    height: 100%;
    border-radius: rem(8px);
    align-items: center;
}

.icon-default { display: flex; }
.icon-hover { display: none; }

// Text
.text {
    text-decoration: none;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(40px);
    color: $primary-navy;
}

//Animations
.slide-in {
    animation: slide-in $animation-duration;
} @keyframes slide-in {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0%); }
}

.slide-out {
    animation: slide-out $animation-duration;
} @keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
}

.shadow {
    -webkit-transform: translateZ(0);
    filter: drop-shadow(0 0 rem(30px) rgba(0, 0, 0, 0.25));
}

.fade-in {
    @extend .shadow;
    animation-name: fade-in;
    animation-duration: $animation-duration;
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-out {
    @extend .shadow;
    animation-name: fade-out;
    animation-duration: $animation-duration;
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

:export {
    animationDuration: $animation-duration;
}