@import "../../new-lib/_styles/index.module.scss";

.background {
  background-color: $background;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(32px) rem(24px) rem(80px);
}

.form-container {
  background-color: $primary-white;
  border-radius: rem(24px);
  
  @include desktop { padding: rem(40px) rem(72px); }
  @include mobile  { padding: rem(32px) rem(16px); }
}

.checkboxes-container {
  @include desktop { margin: rem(32px) 0; }
  @include mobile  { margin: rem(32px) 0 rem(24px) 0; }
}

.reg-form {
  @include desktop { max-width: rem(408px); }
  @include mobile  { max-width: rem(342px); }
}
