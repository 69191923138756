@import '../_styles/variables.module.scss';

.container {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99%;

    @media (min-width: $breakpointTablet) {
        width: 100%;
        margin-bottom: 5px;
    }
}

.infoButtonContainer {
    margin-top: 4px;
}