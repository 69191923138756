@import './index.module.scss';

.ul {
    padding: rem(0px);
    padding-left: rem(40px);
}

.li {
    list-style: disc;
}

.link-to {
    display: inline-flex;
    align-items: center;
}
