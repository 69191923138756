@import '../_styles/variables.module.scss';


.field {
    margin-bottom: 20px !important;
}

.fieldReadonly {
    margin-bottom: 20px !important;
    height: 64px !important;
}


.input {
    border-style: solid !important;
    border-width: 1px !important;
    border-color: rgba(34, 34, 34, 0.2) !important;
    box-shadow: 0px !important;
    overflow: hidden !important;
    border-radius: 5px !important;
    height: 25px !important;
    background-color: #ffffff !important;
    font-family: AvenirLTStdBook !important;
    font-size: 16px !important;
    text-align: left !important;
    color: lighten($black, 15%) !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    
}

.inputReadonly {
    padding-left: 15px !important;
    padding-right: 15px !important;
    box-shadow: 0px !important;
    overflow: hidden !important;
    border-radius: 5px !important;
    height: 25px !important;
    min-width: 200px !important;
    font-family: AvenirLTStdBook !important;
    font-size: 16px !important;
    text-align: left !important;
    color: lighten($black, 15%) !important;
    
}

.label {
    font-family: AvenirLTStdBook;
    top: 4.5px !important;
    padding-left: 5px !important;
}

.labelReadonly {
    @extend .label;
    padding-left: 15px !important;
}

.labelFocused {
    color: $primary !important;
    font-family: AvenirLTStdHeavy !important;
    font-size: 17px !important;
    padding-left: 5px !important;
}

.labelFocusedDisabled {
    color: lighten($black, 15%) !important;
    font-family: AvenirLTStdHeavy !important;
    font-size: 17px !important;
    padding-left: 5px !important;
}

.labelFocusedReadonly {
    color: lighten($black, 15%) !important;
    font-family: AvenirLTStdHeavy !important;
    padding-left: 20px !important;
    
}

.labelError {
    color: $alert !important;
    font-family: AvenirLTStdBook !important;
    font-size: 16px !important;
    padding-left: 5px !important;
}

.labelFocusedError {
    @extend .labelFocused;
    color: $alert !important;
    font-family: AvenirLTStdHeavy !important;
}


.formFieldHelper {
    @extend .labelFocused;
    color: $alert !important;
    font-family: AvenirLTStdHeavy !important;
    font-size: 12px !important;
}
