@import "../../lib/_styles/variables.module.scss";

.root {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 0.1px;

    @media (min-width: $breakpointTablet) {
        width: 600px;
        margin: 0 auto;
    }
}
