@import "../_styles/index.module.scss";

.animation {
    margin: 0;
    color: #0D3050;
    font-family: 'Proxima Nova';
    align-items: center;
    font-size: rem(16px);
    font-weight: 400;
    line-height: rem(24px);
    position: relative;
    padding-bottom: rem(1px);
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: rem(1px);
      background-color: $primary-navy;
      visibility: hidden;
      transition: 120ms linear;
      transform: scaleX(0);
    }
    &:hover::after,:focus::after {
      visibility: visible;
      transform: scaleX(1);
    }
}

.regular {
  color: #0D3050;
    font-family: 'Proxima Nova';
    align-items: center;
    font-size: rem(16px);
    font-weight: 400;
    line-height: rem(24px);
    position: relative;
    box-shadow: 0 rem(1px) 0 $primary-navy;
    margin: 0;
}

.text {
    color: #0D3050;
    font-family: 'Proxima Nova';
    align-items: center;
    font-size: rem(16px);
    font-weight: 400;
    line-height: rem(24px);
    position: relative;
    margin: 0;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: rem(1px);
        background-color: $primary-navy;
        transition: 120ms linear;
        transform: scaleX(1);

      }
      &:hover::after,:focus::after {
        visibility: visible;
        transform: scaleX(0.5);
        cursor: pointer;
      }
}

.container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.centered {
    margin: 0;
    top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}
