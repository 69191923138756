.menuItem {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    width: 100% !important;
    text-decoration: none !important;
    
}

.link {
    text-decoration: none !important;
    color: inherit;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
}

.leftContainer {
    flex: 1;
    display: flex !important;
    flex-direction: row !important;
    
}

.rightContainer {
    flex-direction: row;
}
