@import "../_styles/index.module.scss";

.container {
  background-color: $secondary-middle-blue;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    max-width: rem(342px);
    padding: rem(40px);
  }

  @include desktop {
    max-width: rem(264px);
    padding: rem(40px) rem(16px);
  }

  &-wide {
    @include desktop {
      max-width: rem(360px);
      padding: rem(40px) rem(24px);
    }
  }
}

.button-container { text-align: center; }

.middle-blue   { background-color: $secondary-middle-blue; }
.light-blue    { background-color: $secondary-light-blue; }
.middle-gray   { background-color: $secondary-middle-gray; }
.light-gray    { background-color: $secondary-light-gray; }
.middle-green  { background-color: $secondary-middle-green; }
.light-green   { background-color: $secondary-light-green; }
.middle-salmon { background-color: $extra-middle-salmon; }
.light-salmon  { background-color: $extra-light-salmon; }
.middle-corn   { background-color: $extra-middle-corn; }
.light-corn    { background-color: $extra-light-corn; }

$radius: rem(24px);
.radius-top    { border-top-left-radius: $radius; border-top-right-radius: $radius; }
.radius-right  { border-top-right-radius: $radius; border-bottom-right-radius: $radius; }
.radius-bottom { border-bottom-right-radius: $radius; border-bottom-left-radius: $radius; }
.radius-left   { border-bottom-left-radius: $radius; border-top-left-radius: $radius; }