@import '../../new-lib/_styles/index.module.scss';

.box {
  width: 100%;
  height: 100vh;
  background-color: $primary-white;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 33%;
  height: 80%;
}

.embedded-container {
  @extend .container;
  top: 25%;
}