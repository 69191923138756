@import '../_styles/index.module.scss';

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/fonts/Proxima-Nova-Regular.ttf');
}

.base {
  border-radius: rem(24px);
  border: none;
  box-sizing: border-box;
  padding: rem(12px) rem(32px);
  height: rem(48px);
  min-width: rem(144px);
  font-family: 'Proxima Nova';
  font-size: rem(14px);
  font-weight: 700;
  letter-spacing: rem(1px);
  line-height: rem(16px);
  text-transform: uppercase;
  font-style: normal;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: rem(4px) solid $accents-focused;
  }
}

.icon-default {
  display: flex;
}

.small-base {
  height: rem(40px);
  padding: rem(8px) rem(24px);
  min-width: rem(139px);
  border-radius: rem(24px);
  font-family: 'Proxima Nova';
  font-size: rem(12px);
  font-weight: 700;
  letter-spacing: rem(1px);
  line-height: rem(16px);
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:focus-visible {
    outline: rem(4px) solid $accents-focused;
  }
}

.link-button-base {
  display: inline-flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.primary {
  @extend .base;
  color: $primary-white;
  background-color: $primary-navy;
  font-family: 'Proxima Nova';

  &:hover {
    background-color: $accents-hover-blue;
  }

  &:active {
    background-color: $accents-pressed;
  }

  &-disabled {
    background-color: $primary-navy-disabled;
    pointer-events: none;
    cursor: default;

    &:hover {
      background-color: $primary-navy-disabled;
    }
    &:focus {
      background-color: $primary-navy-disabled;
      outline: 0;
    }
  }
}

.secondary {
  @extend .base;
  background-color: transparent;
  color: $primary-navy;
  border: rem(1px) solid;
  font-family: 'Proxima Nova';

  &:hover {
    border-color: $accents-hover-blue;
    color: $accents-hover-blue;
  }

  &:active {
    border-color: $accents-pressed;
    color: $accents-pressed;
  }
}

.tertiary {
  @extend .base;
  background-color: $secondary-barbel-blue;
  color: $primary-navy;
  font-family: 'Proxima Nova';

  &:hover {
    background-color: $secondary-middle-blue;
  }

  &:active {
    background-color: $secondary-middle-blue;
    opacity: 0.7;
  }
}

.light {
  border-color: $secondary-middle-blue;
}

.dark {
  border-color: $primary-navy;
}

.secondary-disabled {
  border-color: #cccccc;
  color: $disabled-text;
  pointer-events: none;
  cursor: default;

  &:hover {
    border-color: #cccccc;
    color: $disabled-text;
  }
  &:focus {
    border-color: #cccccc;
    color: $disabled-text;
    outline: 0;
  }
}

.text {
  @extend .base;
  border-color: transparent;
  background-color: transparent;
  color: $primary-navy;

  // Underline
  & .button-text {
    box-shadow: 0 rem(1px) 0 $primary-navy;
  }

  &:hover {
    background-color: $accents-hover-blue;
    color: $primary-white;
    & .button-text {
      box-shadow: 0 rem(1px) 0 $primary-white;
    }
  }

  &:active {
    background-color: $accents-pressed;
  }

  &-disabled {
    background-color: transparent;
    color: rgba(13, 48, 80, 0.5);
    & .button-text {
      box-shadow: 0 rem(2px) 0 rgba(13, 48, 80, 0.5);
    }
    pointer-events: none;
    cursor: default;

    &:hover {
      color: rgba(13, 48, 80, 0.5);
      background-color: transparent;
    }

    &:focus {
      color: rgba(13, 48, 80, 0.5);
      background-color: transparent;
      outline: 0;
    }
  }
}

.secondary-danger {
  @extend .base;
  color: $error;
  //   background-color: $error;
  border: rem(1px) solid $error;

  &:hover {
    background-color: $error-hover;
    color: $primary-white;
    border: rem(1px) solid $error;
  }

  &:active {
    background-color: $error-pressed;
  }

  &-disabled {
    background-color: $error-disabled;
    border-color: $error-disabled;
    pointer-events: none;
    cursor: default;

    &:hover {
      background-color: $error-disabled;
      border-color: $error-disabled;
    }
    &:focus {
      background-color: $error-disabled;
      border-color: $error-disabled;
      outline: 0;
    }
  }
}

.danger {
  @extend .base;
  color: $primary-white;
  background-color: $error;
  border: rem(1px) solid $error;

  &:hover {
    background-color: $error-hover;
  }

  &:active {
    background-color: $error-pressed;
  }

  &-disabled {
    background-color: $error-disabled;
    border-color: $error-disabled;
    pointer-events: none;
    cursor: default;

    &:hover {
      background-color: $error-disabled;
      border-color: $error-disabled;
    }
    &:focus {
      background-color: $error-disabled;
      border-color: $error-disabled;
      outline: 0;
    }
  }
}

.button-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.expand {
  width: 100%;
}

.loading {
  pointer-events: none;
}
