@import "../../new-lib/_styles/index.module.scss";

.info-header {
  background-color: $secondary-middle-blue;
  display: flex;
  justify-content: center;

  @include desktop {
    padding: rem(88px) 0;
  }
  @include mobile {
    padding: rem(40px) rem(24px);
  }
}

.info-header-content {
  @include desktop {
    max-width: rem(552px);
  }
  @include mobile {
    max-width: rem(342px);
  }
}



.body-container {
  background-color: $primary-white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @include mobile {
    padding-left: rem(24px);
    padding-right: rem(24px);
  }

  @include desktop {
    padding-left: rem(48px);
    padding-right: rem(48px);
  }
}

.body-content {
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: rem(40px) 0 rem(80px);
    gap: rem(40px);

    max-width: rem(342px);
  }
  @include desktop {
    padding: rem(56px) 0 rem(104px);
    gap: rem(56px);

    max-width: rem(1128px);
  }
}



.prompt-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  
  @include mobile {
    display: flex;
    flex-direction: column;
    row-gap: rem(32px);
  }
  @include desktop {
    column-gap: rem(32px);
  }
}

.prompt-card-image-half {
  width: 100%;
  height: 100%;
  
  @include mobile {
    border-top-left-radius: rem(24px);
    border-top-right-radius: rem(24px);
  }
  
  @include desktop {
    border-top-left-radius: rem(24px);
    border-bottom-left-radius: rem(24px);
  }
}

.prompt-card-info-half {
  background-color: aqua;
  background-color: $secondary-light-gray;
  box-sizing: border-box;
  
  display: flex;
  gap: rem(24px);

  width: 100%;

  @include mobile {
    flex-direction: column;

    border-bottom-left-radius: rem(24px);
    border-bottom-right-radius: rem(24px);
    padding: rem(32px) rem(16px)
  }
  
  @include desktop {
    border-top-right-radius: rem(24px);
    border-bottom-right-radius: rem(24px);
    padding: rem(56px);
  }
}

.prompt-card-statements {
  @include desktop {
    min-width: rem(280px);
    max-width: rem(340px);
  }
}

.prompt-card-body-text {
  @include desktop {
    max-width: rem(255px);
  }
}

.statement-container {
  display: flex;
  align-items: center;
  gap: rem(4px);
}



.select-unit-container {
  background-color: $secondary-barbel-blue;
  border-radius: rem(8px);
  box-sizing: border-box;
    
  display: flex;
  justify-content: center;

  @include mobile {
    padding: rem(32px) rem(16px);
  }
  
  @include desktop {
    padding: rem(56px) 0;
  }

}

.select-unit-content {
  box-sizing: border-box;
  
  @include desktop {
    max-width: rem(456px);
  }

  @include mobile {
    width: 100%;
  }
}

.buttons {
  display: flex;

  @include mobile {
    flex-direction: column;
    gap: rem(16px);
  }

  @include desktop {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}