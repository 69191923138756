@import '../../new-lib/_styles/index.module.scss';

.body {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include mobile  { padding: rem(32px) rem(24px) rem(80px); }
  @include desktop { padding: rem(56px) rem(56px) rem(104px); }
}

.filter-container {
  width: 100%;
  background-color: $secondary-light-blue;
  margin-bottom: rem(32px);
  display: flex;
  justify-content: center;

  @include mobile {
    padding: rem(24px) rem(16px);
  }

  @include desktop {
    max-width: rem(1128px);
    padding: rem(32px) 0;
  }
}

.filter-content {
  display: flex;
  flex-direction: column;
  
  @include mobile {
    min-width: rem(280px);
    gap: rem(24px);
  }

  @include desktop {
    gap: rem(16px);
  }
}

.accordion-body-content {
  margin: 0 auto;
  @include mobile  { max-width: rem(310px); }
  @include desktop { max-width: rem(408px); }
}

.accordion-phone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: rem(24px);
}

.phone { text-decoration: none; }
