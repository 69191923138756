@import './utils/variables.scss';
@import './utils/functions.scss';


@font-face {
  font-family: AvenirLTStd;
  src: url("assets/fonts/AvenirLTStd-Black.otf") format("opentype");
}

@font-face {
  font-family: AvenirLTStdLight;
  src: url("assets/fonts/AvenirLTStd-Light.otf") format("opentype");
}
@font-face {
  font-family: AvenirLTStdBook;
  src: url("assets/fonts/AvenirLTStd-Book.otf") format("opentype");
}

@font-face {
  font-family: AvenirLTStdHeavy;
  src: url("assets/fonts/AvenirLTStd-Heavy.otf") format("opentype");
}

* {
  box-sizing: border-box;
  font-family: 'Proxima Nova', 'Avenir LT W01_45 Book1475508', AvenirLTStdBook, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  @media only screen and (max-width: 520px) {

  }
  padding: 0;
  font-size: $rem-base + px;
  color: $font-color;
  h1, h2 {
    font-family: 'Avenir LT W01_85 Heavy1475544', AvenirLTStdHeavy, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }

  
}

fieldset {
  border: none;
  padding: 0px;
  margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

#root {
  flex-shrink: 0;
  flex: 1;
  display: flex;
  height: 100%;
}


/**
 * Visually hide an element, but leave it available for screen readers
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
 .screen-reader {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

/**
 * Extends the .screen-reader class to allow the element to be focusable when navigated to via the keyboard
 * @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
 * @link https://www.drupal.org/node/897638
 */
.screen-reader-focusable:active,
.screen-reader-focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	white-space: normal;
	width: auto;
}
