@import '../_styles/index.module.scss';

.cta-container {
  box-sizing: border-box;

  @include mobile {
    max-width: rem(342px);
  }

  @include desktop {
    max-width: rem(552px);
  }
  
  &-with-image {
    display: grid;

    @include mobile {
      grid-template-rows: 2fr 3fr;
      gap: rem(24px);
    }

    @include desktop {
      max-width: rem(1128px);
      grid-template-columns: 1fr 1fr;
    }
  }
}

.image {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: 85%;

  @include mobile  { border-radius: rem(24px) rem(24px) 0 0; }
  @include desktop { border-radius: rem(24px) 0 0 rem(24px); }
}

.content-container {
  background-color: $secondary-barbel-blue;
  border-radius: rem(24px);

  @include mobile  { padding: rem(40px) rem(16px); }
  @include desktop  { padding: rem(40px) rem(24px); }

  &-with-image {
    background-color: $secondary-middle-gray;

    @include mobile {
      border-radius: 0 0 rem(24px) rem(24px);
    }

    @include desktop {
      border-radius: 0 rem(24px) rem(24px) 0;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  
  @include mobile  { gap: rem(32px); margin-top: rem(32px); }
  @include desktop { 
    gap: rem(24px);
    margin-top: rem(40px);
    max-width: rem(342px);
  }
}