@import '../_styles/variables.module.scss';


.li {
    margin-bottom: 20px;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    display: flex;
    flex: 1 0 25%;
    max-width: 32.4%;
    height: 86px;
    overflow: hidden;

    :focus {
        outline-offset: -5px;
    }
}

.centerItem {
    margin-right: 1.35%;
    margin-left: 1.35%;
}


.innerContainer {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    width: 100% !important;
    text-decoration: none !important;
    padding-left: 0.5vw;
    padding-top: 6px;
}


.link {
    text-decoration: none !important;
    color: inherit;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
    
}

.leftContainer {
    flex: 1;
    display: flex !important;
    flex-direction: row !important;
    
}

.rightContainer {
    flex: 1;
    display: flex !important;
    flex-direction: row !important;
    justify-items: center;
}
