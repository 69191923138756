@import '../../utils/variables.scss';
@import '../../utils/functions.scss';

.errorPage {
  word-break: keep-all;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: rem(115);
}

.content {
  flex: 1;

  @media all and (max-width: 520px) {
    background: url('../../assets/error-bg-small.svg') no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  @media all and (min-width: 521px) and (max-width: 1024px) {
    background: url('../../assets/error-bg-medium.svg') no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  @media all and (min-width: 1025px) and (max-width: 3000px) {
    background: url('../../assets/error-bg-big.svg') no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  .textContainer {
    max-width: 768px;
    padding: 40px;
    margin: 0 auto;

    @media all and (max-width: 520px) {
      margin: 2rem 3rem;
    }

    @media all and (min-width: 521px) and (max-width: 1024px) {
      margin: 2rem 7rem;
    }

    .title {
      margin-bottom: 1rem;
    }
  }
}
