@import '../../new-lib/_styles/index.module.scss';

.container {
  background-color: $primary-white;
}

.hero {
  display: flex;
  height: rem(408px);
  width: 100%;

  @include mobile {
    height: fit-content;
  }
}


// View Without
.text-container {
  margin-top: rem(56px);
  margin-bottom: rem(104px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $secondary-light-gray;
  height: rem(312px);

  @include mobile {
    height: rem(320px);
    margin-bottom: rem(80px);
  }
}

.text-box {
  width: rem(744px);
  height: rem(200px);
  padding: rem(56px) rem(0px) rem(56px) rem(0px);
  justify-self: center;

  @include mobile {
    width: rem(342px);
    height: rem(256px);
    padding: rem(32px) rem(0px) rem(32px) rem(0px);
  }

  @media (max-width: 350px) {
    align-items: center;
    justify-content: center;
  }
}




// View with careunit selected
.link-container {
  display: flex;
  flex-direction: column;
  margin-top: rem(56px);
  gap: rem(16px);
  width: rem(552px);

  @include mobile {
    width: rem(342px);
    gap: rem(24px);
    margin-top: rem(32px);
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.action-menu-content {
  display: flex;
  flex-direction: column;
  gap: rem(8px);
  margin-bottom: rem(104px);
    
  @include mobile {
    margin-bottom: rem(80px);
  }
}
