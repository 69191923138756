@import "../../lib/_styles/variables.module.scss";
@import '../../new-lib/_styles/index.module.scss';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: $secondary-light-gray;
  padding: rem(32px) rem(0px) rem(80px) rem(0px);
  
  
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px;
  border-radius: 16px;
  border-color: $secondary-middle-blue;
  background-color: white;
  
  
  @include mobile {
    min-height: rem(340px);
    width: rem(294px);
    padding: rem(40px) rem(24px) rem(40px) rem(24px);
  }

  @include desktop {
    padding: rem(56px) rem(40px) rem(56px) rem(40px);
    width: rem(552px); 

  }
}

.loading-container {
  @extend .container;


}

.button-container {
  width: 100%;
  display: flex;
  justify-content: end;
}