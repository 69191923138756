@import "../_styles/index.module.scss";


$text-primary: #0D3050;
$text-secondary: #FFFFFF;
$text-disabled: #F9FAFB;
$text-error:  #A80000;
$text-grey: #818181;


@font-face {
    font-family: 'Proxima Nova';
    src: url("../../assets/fonts/ProximaNovaNative/ProximaNova-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../../assets/fonts/ProximaNovaNative/ProximaNova-Semibold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("../../assets/fonts/ProximaNovaNative/ProximaNova-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: 'Proxima Nova Italic';
    src: url("../../assets/fonts/Proxima-Nova-Regular-Italic.ttf");
}

.h1-plus {
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    
    @include mobile {
        font-size: rem(48);
        line-height: rem(48);
    }

    @include desktop {
        font-size: rem(64);
        line-height: rem(64);
    }
}


.base {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -webkit-text-stroke: 0;
}

.h1 {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-size: rem(48);
    line-height: rem(48);
    // font-style: normal;
    font-weight: 700;

    @include mobile {
        font-size: rem(32);
        line-height: rem(32);
    }

    @include desktop {
        font-size: rem(48);
        line-height: rem(48);
    }
}

.h2 {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-weight: 700;

    @include mobile {
        font-size: rem(24);
        line-height: rem(24);
    }

    @include desktop {
        font-size: rem(32);
        line-height: rem(32);
    }
}

.h3 {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;

    @include mobile {
        font-size: rem(20);
        line-height: rem(24);
    }

    @include desktop {
        font-size: rem(20);
        line-height: rem(24);
    }
}

.h4 {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    
    @include mobile {
        font-size: rem(16);
        line-height: rem(24);
    }

    @include desktop {
        font-size: rem(16);
        line-height: rem(24);
    }
}

.h5 {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-size: rem(14);
    line-height: rem(24);
    font-weight: 700;
}


.p {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-size: rem(16);
    line-height: rem(24);
    font-style: normal;
    font-weight: 400;
}


.p-wide {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: rem(0.25);
    font-style: normal;
    font-weight: 400;
}

.p-sm {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-size: rem(14);
    line-height: rem(16);
    font-style: normal;
    font-weight: 400;
}

.p-xs {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-size: rem(12);
    line-height: rem(16);
    font-style: normal;
    font-weight: 400;
}

.em {
    @extend .base;
    color: $text-primary;
    font-family: 'Proxima Nova';
    font-size: rem(16);
    line-height: rem(24);
    font-style: normal;
    font-weight: 600;
}

.label {
    @extend .base;
    color: $text-primary;
    font-family: "Proxima Nova";
    font-size: rem(16px);
    line-height: rem(24px);
    letter-spacing: rem(0.25px);
    font-style: normal;
    font-weight: 400;
}

.input-error {
    @extend .base;
    @extend .p-xs;
    color: $text-error;
}

.label-error {
    @extend .base;
    @extend .p-wide;
    color: $text-error;
}

.span {
    @extend .base;
    color: $text-primary;
    font-family: "Proxima Nova";
    font-size: rem(20px);
    line-height: rem(32px);
    font-style: normal;
    font-weight: 400;
}

.color-primary { color: $text-primary; }
.color-secondary { color: $text-secondary}

.weight-400 { font-weight: 400; }
.weight-600 { font-weight: 600; }
.weight-700 { font-weight: 700; }

.font-size-xs { font-size: rem(12px); }
.font-size-sm { font-size: rem(14px); }
.font-size-md { font-size: rem(16px); }
.font-size-lg { font-size: rem(18px); }
.font-size-xl { font-size: rem(20px); }

.line-height-xs { line-height: rem(16px); }
.line-height-sm { line-height: rem(20px); }
.line-height-md { line-height: rem(24px); }
.line-height-lg { line-height: rem(28px); }
.line-height-xl { line-height: rem(32px); }

.letter-spacing-sm { letter-spacing: rem(0.1px); }
.letter-spacing-md { letter-spacing: rem(0.2px); }
.letter-spacing-lg { letter-spacing: rem(0.5px); }
.letter-spacing-xl { letter-spacing: rem(1px); }

.display-inline { display: inline; }
.display-block  { display: block; }

.right { text-align: right; }
.center { text-align: center; }
.left { text-align: left; }
.start { text-align: start; }
.end { text-align: end; }
.justify { text-align: justify; }
.inherit { text-align: inherit; }
.initial { text-align: initial; }
.unset { text-align: unset; }
