@import "../_styles/index.module.scss";


.skeletonWrapper {
  position: relative;
  overflow: hidden;
  border-radius: rem(16px);
  background-color: #EEEEEE;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 1.5s infinite;
    content: '';
  }

}

.checkbox {
  display: flex;
  min-width: rem(120px);
  align-items: center;
  margin-top: rem(16px);
}

.loaders {
  display: flex;
  flex-direction: column;
  gap: rem(8px);
  width: 100%;
}

.second-loader {
  display: flex;
  flex-direction: column;
  width: rem(115px);

  @include desktop {width: rem(200px); }
}


@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
