@import "../_styles/index.module.scss";

.button {
    display: flex;
    align-self: center;
    background-color: transparent;
    border: rem(1px) solid transparent;
    margin: rem(6px) rem(6px) auto 0;
    padding: 0;
    cursor: pointer;
}

.container {
    display: flex;
    align-items: center;
}


.second {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: rem(14px)
}
