@import '../_styles/variables.module.scss';

.root {
    background-color: $primary !important;
    justify-content: center;
    height: 90px;
    @media (max-width: $breakpointTablet - 0.001) {
        height: 60px;
    }
}
.logoContainer {
    display: flex;
    flex: 1;
    justify-content: center;    
}