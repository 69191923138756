@import "../_styles/index.module.scss";

.default {
  text-decoration: none;
  display: inline-block;
}

.expand { width: 100%; }

.animation {
  position: relative;
  padding-bottom: rem(2px);
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(2px);
    background-color: $primary-navy;
    visibility: hidden;
    transition: 120ms linear;
    transform: scaleX(0);
  }
  &:hover::after,:focus::after {
    visibility: visible;
    transform: scaleX(1);
  }
}
