@import '../_styles/index.module.scss';


$animation-duration: 0.4s;
.accordion {
  box-sizing: border-box;
  background-color: $primary-white;
  box-shadow: 0 rem(-1px) 0 $secondary-middle-blue,
              0 rem(1px)  0 $secondary-middle-blue;

  &:hover { background-color: $secondary-light-blue; }
  
  &-open  {
    background-color: $secondary-light-blue;
    .chevron-icon { transform: rotate(-180deg); }
  }
  
  &-slim { .accordion-header { min-height: rem(56px); } }
  
  &-with-icon {
    .accordion-header {
      @include desktop { padding: rem(16px) rem(24px); }
    }
  }
              
  @include mobile  { width: rem(342px); }
  @include desktop { width: rem(552px); }
}

.expand { width: 100%; }

.left-icon {
  @include mobile  { margin-right: rem(16px); }
  @include desktop { margin-right: rem(24px); }
}

.chevron-icon {
  margin-left: auto;
  transition: transform $animation-duration;
}

.accordion-header {
  box-sizing: border-box;
  min-height: rem(112px);
  display: flex;
  align-items: center;
  padding: rem(16px);
}

.header-content {
  @include mobile  { margin-right: rem(16px); }
  @include desktop { margin-right: rem(24px); }
}

.accordion-body {
  overflow: hidden;
  transition: max-height $animation-duration;
  padding: 0 rem(16px);
}

// adding margin or padding to other parent elements causes some of the body content to appear in the
// header section. having a div that does nothing other than have margin-bottom lets us have some space
// between the end of body content and bottom border without causing weird rearrangement of other components.
.button-margin-bottom-filler-div { margin-bottom: rem(16px); }

.skeleton {
  box-sizing: border-box;
  background-color: $primary-white;
  box-shadow: 0 rem(-1px) 0 $secondary-middle-blue,
              0 rem(1px)  0 $secondary-middle-blue;

  @include mobile  { width: rem(342px); }
  @include desktop { width: rem(552px); }

  display: flex;
  align-items: center;
  padding: rem(16px);
  gap: rem(16px);

  height: rem(112px);
  &-slim { @extend .skeleton; height: rem(56px); }
}

.expand-height { height: 100%; }

@keyframes fadeAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
}
@-o-keyframes fadeAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
}
@-moz-keyframes fadeAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
}
@-webkit-keyframes fadeAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.6; }
  100% { opacity:1; }
}
$skeleton-animate-duration: 1.5s;
.animation {
  -webkit-animation: fadeAnimation $skeleton-animate-duration infinite;
  -moz-animation: fadeAnimation $skeleton-animate-duration infinite;
  -o-animation: fadeAnimation $skeleton-animate-duration infinite;
  animation: fadeAnimation $skeleton-animate-duration infinite;
}

.skeleton-icon {
  @extend .animation;
  flex-shrink: 0;
  background-color: $background;
  border-radius: rem(8px);
  width: rem(32px);
  height: rem(32px);
}

.skeleton-header {
  @extend .animation;
  background-color: $background;
  border-radius: rem(8px);
  width: 100%;
  height: rem(32px);
}
