@import '../../new-lib/_styles/index.module.scss';

.hero {
  &-header {
    box-sizing: border-box;
    background-color: $secondary-middle-green;
    display: flex;
    justify-content: center;
    
    @include mobile  { padding: rem(40px) rem(24px); }
    @include desktop { padding: rem(88px) 0; }
  }
  &-content {
    @extend .content-container;
    @include desktop { padding-right: rem(672px); }
  }
}

.body {
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @include mobile  { padding: rem(32px) rem(24px) rem(80px); }
  @include desktop { padding: rem(56px) 0 rem(104px); gap: rem(64px);}
}

.content-container {
  @include mobile  { max-width: rem(540px); }
  @include desktop { max-width: rem(1128px); }
}

.edit-information-card {
  background-color: $secondary-light-green;
  border-radius: rem(16px);
  display: flex;

  @include mobile  {
    flex-direction: column;
    padding: rem(24px) rem(16px) rem(16px);
    gap: rem(32px);
  }

  @include desktop {
    padding: rem(40px) rem(72px);
    gap: rem(168px);
  }
}

.contact-details-container {
  display: flex;
  flex-direction: column;
  gap: rem(16px);

  @include desktop { width: rem(408px); }
}

.consents-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include desktop { width: rem(408px); }
}

.consents {
  display: flex;
  flex-direction: column;
  gap: rem(12px);
  
  @include mobile { margin-bottom: rem(48px); }
}

.has-saved-changes-text-container {
  display: flex;
  justify-content: center;
  height: rem(25px);
  margin-top: rem(8px);
}

.remove-account-card {
  box-sizing: border-box;
  border: rem(3px) solid $secondary-middle-blue;
  border-radius: rem(24px);

  display: flex;
  flex-direction: column;

  @include mobile  { padding: rem(32px) rem(24px); margin: rem(48px) 0; }
  @include desktop { padding: rem(32px); margin: rem(64px) 0; }
}

.validate-phone-number-card {
  box-sizing: border-box;
  border: rem(3px) solid $secondary-coral-66;
  border-radius: rem(24px);

  display: flex;
  flex-direction: column;
  gap: rem(24px);

  @include mobile  { padding: rem(32px) rem(24px); margin: rem(48px) 0; }
  @include desktop { padding: rem(32px); margin: rem(64px) 0; }
}

.validate-phone-number-texts-container {
  display: flex;
  flex-direction: column;

  @include mobile { gap: rem(8px); }
  @include desktop { gap: rem(16px); }
}

.validate-phone-number-button-cointainer {
  display: flex;
  @include desktop {
    flex-direction: row-reverse;
  }
}

.hidden {
  display: none;
}
