@import '../../new-lib/_styles/index.module.scss';


.body{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-list {
  list-style: none;
  padding: 0;
}

.container {
  margin-top: rem(32px);
  margin-bottom: rem(128px);
  display: flex;
  flex-direction: column;
  gap: rem(14px);

  @include desktop {
    margin-top: rem(56px);
    flex-direction: row;
  }
}

.sections-container {
  display: flex;
  flex-direction: column;
}

.section {
  margin-bottom: rem(64px);

  @include mobile {
    margin-bottom: rem(32px);
  }
}



.loader-and-error-container {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 rem(32px);
}