@import '../../new-lib/_styles/index.module.scss';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: $secondary-light-gray;
  padding: rem(56px) rem(0px) rem(56px) rem(0px);
  
  
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px;
  border-radius: 16px;
  border-color: $secondary-middle-blue;
  background-color: white;
  
  
  @include mobile {
    min-height: rem(340px);
    width: rem(342px);
    padding: rem(56px) rem(24px) rem(56px) rem(24px);
  }

  @include desktop {
    padding: rem(56px) rem(24px) rem(56px) rem(24px);
    width: rem(360px); 
  }
}
