@import "../_styles/variables.module.scss";

.container {
    //width: 600px;
    background-color: #f7f0c8;
    padding: 20px;
}

.body {
    padding-top: 8px  !important;
}