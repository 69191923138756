@import '../_styles/variables.module.scss';

.checkbox {
    :checked {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: inline-block;
    }
}

.iconChecked {
    color: #fff;
}

.icon {
    color: transparent;
}

.circle {
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.iconContainer {
    @extend .circle;
    border-color: $primary;
    font-size: 26px;
}

.iconContainerDisabled {
    @extend .iconContainer;
    border-color: $gray;
}

.iconCheckedContainer {
    @extend .iconContainer;
    background-color: $primary;
    
}

.iconCheckedContainerDisabled {
    @extend .iconContainerDisabled;
    background-color: $gray;
}


.label {
    color: $black;
    font-family: AvenirLTStdBook !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
}

.labelDisabled {
    @extend .label;
    color: $grayBright;
}