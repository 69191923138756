@import '../_styles/index.module.scss';

.card {
  background: linear-gradient(
    to right,
    $secondary-light-blue,
    $secondary-light-blue rem(56px),
    $primary-white 0
  );  
  border: rem(1.5px) solid $secondary-middle-blue;
  border-radius: rem(24px);

  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  
  display: flex;
  flex-direction: column;
  padding: rem(16px);
  
  @include mobile  {
    width: rem(310px);
    min-height: rem(72px);
  }
  
  @include desktop {
    width: rem(360px);
    min-height: rem(80px);
  }

  

  &-wide {
    /* TODO: Creating a bug for this. I think we should replace the wide prop with "expand".
    Allowing the card to expand the full-width of its parent instead of being locked to a fixed width.
    Not very flexible.
    */
    @include mobile  { width: rem(342px); }
    @include desktop { width: rem(552px); }
  }

  &-expand {
    width: auto;
  }

  &-with-one-label-or-less { justify-content: center; }

  &-dumb {
    pointer-events: none;
    
  }

  &:hover { border-color: $accents-hover-blue; }
}

.top-row {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 99fr 1fr;
  align-items: center;
}

.icon-default {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width:  rem(24px);
  height: rem(24px);
}

.right-icon {
  @extend .icon-default;
  justify-content: end;
  margin-left: auto;
}

.title-container { margin-left: rem(32px); padding-right: rem(12px); }

.labels-container { margin-left: rem(56px); }


// Centered icons variant
.card-centered-icons {
  display: flex;
  flex-direction: row;
}

.centered-icons-default {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.centered-icons-left-icon {
  @extend .centered-icons-default;
  max-width: rem(24px);
}

.centered-icons-text-container {
  margin-left: rem(32px);
  padding-right: rem(12px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centered-icons-right-icon {
  @extend .centered-icons-default;
}
