.sboard-layout { width: 100%; }

.child-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 1.5rem;
}
