@import '../../new-lib/_styles/index.module.scss';

.body {
  background-color: $background;

  display: flex;
  justify-content: center;

  @include mobile {
    background-color: $primary-white;
    padding-top: rem(32px);
    padding-bottom: rem(104px);
  }

  @include desktop {
    padding: rem(56px) 0 rem(104px);
  }
}

.card {
  background-color: $primary-white;
  box-sizing: border-box;

  @include mobile {
    max-width: rem(342px);
  }

  @include desktop {
    max-width: rem(600px);
    border-radius: rem(24px);
    padding: rem(32px) rem(24px) rem(24px);
  }
}

.back-link {
  text-decoration: none;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  gap: rem(12px);

  margin-bottom: rem(24px);

  // animation
  position: relative;
  padding-bottom: rem(4px);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(2px);
    background-color: $primary-navy;
    visibility: hidden;
    transition: 120ms linear;
    transform: scaleX(0);
  }

  &:hover::after,
  :focus::after {
    visibility: visible;
    transform: scaleX(1);
  }
}

.link-rows-container {
  margin: rem(8px) 0 rem(24px);
}