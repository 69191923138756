@import "../_styles/index.module.scss";

.logged-in-content {
  flex: 1;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row-reverse;
}

.inbox-btn-desktop {
  @extend .logged-in-content;
  margin-right: -40px;
}

.inbox-btn-mobile {
  @extend .logged-in-content;
  margin-right: 16px;
}

.chat-variant {
  display: flex;
  align-items: center;
  margin-left: auto;

  @include mobile  { gap: rem(8px); }
  @include desktop { gap: rem(16px); }
}

.button-container {
  @include desktop { display: initial; }
  @include mobile { display: none; }
}

.hamburger {
  width: rem(32px);
  height: rem(16px);
  cursor: pointer;

  @include desktop { display: none; }
  @include mobile { display: initial;}
}

.logged-in-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-container {
  background-color: $secondary-bg;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.chat-header-container {
  @extend .header-container;
  position: sticky;
  top: 0px;
}

.back-link-container {
  background-color: $secondary-bg;
}

.back-to-link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin: rem(16px) 0;
  gap: rem(20px);
  
  @include desktop { margin-left: rem(156px); }
  @include mobile { margin-left: rem(24px); }
}

.header {
  background-color: $primary-white;
  border-bottom: rem(1px) solid $secondary-middle-blue;
  display: flex;
  
  @include desktop { padding: 0 rem(100px) 0; }
  @include mobile { padding: 0 rem(24px) 0; }
}

.logo-link {
  @include desktop { margin: rem(40px) rem(56px); }
  @include mobile { margin: rem(20px) rem(24px); }
}

.logo-image {
  @include desktop {
    width: rem(168px);
    height: rem(48px);
  }

  @include mobile {
    width: rem(80px);
    height: rem(24px);
  }
}

.sticky-header {
  position: fixed;
  width: 100%;
  top: 0px;
  align-self: flex-end;
}
