.ul {
    padding: 0px;
    padding-left: 40px;
}

.li {
    list-style: disc;
}

.loader {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.terms-of-conditions {
    font-family: AvenirLTStd;
}

.accept-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        width: 100%;
    }
}
