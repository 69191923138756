$breakpointMobileSm: 380px;
$breakpointTablet: 600px;
$breakpointLaptop: 1280px;
$breakpointDesktop: 1600px;
$breakpointDesktopLg: 2200px;

$blue: #2278d3;
$boneWhite: #f7f0c8;
$orange: #fa8254;
$black: #222222;
$gray: #eeeeee;
$grayBright: #f5f5f5;
$red: #fa5454;
$green: #3ac57e;
$limeGreen: #8dc63f;
$white: #ffffff;

$primary: $blue;
$primaryHover: darken($primary, 15%);
$secondary: $boneWhite;
$tertiary: $orange;
$alert: $red;
$success: $green;
$doctrinSuccess: $limeGreen;

:export {
    breakpointTablet: $breakpointTablet;
    breakpointLaptop: $breakpointLaptop;
    breakpointDesktop: $breakpointDesktop;
    breakpointMobileSm: $breakpointMobileSm;
    breakpointDesktopLg: $breakpointDesktopLg;
    blue: $blue;
    boneWhite: $boneWhite;
    orange: $orange;
    black: $black;
    gray: $gray;
    grayBright: $grayBright;
    red: $red;
    green: $green;
    limeGreen: $limeGreen;
    white: $white;
    primary: $primary;
    primaryHover: $primaryHover;
    secondary: $secondary;
    tertiary: $tertiary;
    alert: $alert;
    success: $success;
    doctrinSuccess: $doctrinSuccess;
}
