@import '../_styles/index.module.scss';

.ul {
    padding: rem(0px);
    padding-left: rem(40px);
}

.li {
    list-style: disc;
}

.loader {
    display: flex;
    justify-content: center;
}

.terms-of-conditions {
    font-family: "Proxima Nova";
}
