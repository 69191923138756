@import "../_styles/index.module.scss";

.card-base {
  border-radius: rem(24px);
  background-color: $primary-white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-lg {
  @include mobile {
    width: rem(342px);
    max-height: rem(572px);
  }
  @include desktop {
    width: rem(552px);
    max-height: rem(692px);
  }
}

.card-md {
  @include mobile {
    width: rem(342px);
    max-height: rem(572px);
  }
  @include desktop {
    width: rem(408px);
    max-height: rem(688px);
  }
}

.card-sm {
  @include mobile {
    width: rem(310px);
    max-height: rem(456px);
  }
  @include desktop {
    width: rem(360px);
    max-height: rem(496px);
  }
}
