@import '../../utils/variables.scss';
@import '../../utils/functions.scss';

.h1 {
  font-weight: 900;
  font-size: rem(28);
  margin: 0;

  &.bold {
    font-weight: 600;
  }
}

.h2 {
  font-family: white;
  font-weight: 200;
  font-size: rem(24);
  margin: 0;

  &.bold {
    font-weight: 600;
  }
}

.h3 {
  font-weight: 300;
  font-size: rem(20);
  margin: 0;

  &.bold {
    font-weight: 600;
  }
}

.h4 {
  font-weight: 900;
  font-size: rem(20);
  margin: 0;

  &.bold {
    font-weight: 600;
  }
}

.italic {
  font-style: italic;
}

.contain {
  overflow: scroll;
  padding-bottom: rem(75) !important;
  -webkit-overflow-scrolling: touch;
}

.body {
  margin: 0;
  padding: 0;
  font-size: rem(18);
  line-height: normal;
  -webkit-text-size-adjust: none;
}

.body2 {
  font-weight: 300;
  font-size: rem(20);
  margin: 0;

  &.bold {
    font-weight: 600;
  }
}

.bodyLeftAligned {
  margin: 0;
  padding: 0;
  font-size: rem(14);
  line-height: normal;
  -webkit-text-size-adjust: none;
  text-align: left;
}
