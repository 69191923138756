@import "../_styles/index.module.scss";

.base {
  box-sizing: border-box;
  width: rem(48px);
  height: rem(48px);
  border-radius: 50%;
  border: rem(1px) solid $primary-navy;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:focus { outline: rem(4px) solid $accents-focused; }
}

.primary {
  @extend .base;
  background-color: $primary-navy;

  &:hover { background-color: $accents-hover-blue; }
  &:focus { background-color: $accents-pressed; }

  &-disabled {
    background-color: $primary-navy-disabled;
    border-color: $primary-navy-disabled;
    pointer-events: none;
    cursor: default;
    
    &:hover {
        background-color: $primary-navy-disabled;
        border-color: $primary-navy-disabled;
    }
    &:focus {
        background-color: $primary-navy-disabled;
        border-color: $primary-navy-disabled;
        outline: 0;
    }
  }
}

.secondary {
  @extend .base;
  background-color: $secondary-middle-blue;
  border-color: $secondary-middle-blue;

  &:hover { border-color: $accents-hover-blue; }
  &:focus { border-color: $secondary-middle-blue; }

  &-disabled {
    background-color: rgba(13, 48, 80, 0.4);
    pointer-events: none;
    cursor: default;
    
    &:hover {
        border-color: #CCCCCC;
        color: $disabled-text;
    }
    &:focus {
        border-color: #CCCCCC;
        color: $disabled-text;
        outline: 0;
    }
  }
}

.danger {
  @extend .base;
  background-color: $error;
  border-color: $error;

  &:hover { background-color: $error-hover; border-color: $error-hover; }
  &:focus { background-color: $error-focus; border-color: $error-focus; }

  &-disabled {
    background-color: $error-disabled;
    border-color: $error-disabled;
    pointer-events: none;
    cursor: default;
    
    &:hover {
        background-color: $error-disabled;
        border-color: $error-disabled;
    }
    &:focus {
        background-color: $error-disabled;
        border-color: $error-disabled;
        outline: 0;
    }
  }
}
