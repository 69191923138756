@import "../_styles/index.module.scss";

.container {
  text-decoration: none;
  background-color: $secondary-light-blue;
  padding: rem(16px);
  display: flex;
  align-items: center;
  box-sizing: border-box;

  & > * { cursor: pointer; }

  @include desktop {
    width: rem(360px);
    padding-right: rem(32px);
  }
  @include mobile {
    width: rem(310px);
    padding-right: rem(24px);
  }

  &-wide {
    @extend .container;

    @include desktop { width: rem(552px); }
    @include mobile { width: rem(342px); }
  }

  &:hover { outline: rem(1.5px) solid $accents-hover-blue ;}
  &:hover .icon-default  { display: none; }
  &:hover .icon-on-hover { display: flex; }
}

.icon-default  { display: flex; }
.icon-on-hover { display: none; }

.arrow-icon-container {
  margin-left: auto;
  align-items: center;
}

$radius: 8px;
.radius-top { 
  border-top-left-radius: rem($radius);
  border-top-right-radius: rem($radius);
}
.radius-bottom {
  border-bottom-left-radius: rem($radius);
  border-bottom-right-radius: rem($radius);
}

.middle-blue   { background-color: $secondary-middle-blue; }
.light-blue    { background-color: $secondary-light-blue; }
.middle-gray   { background-color: $secondary-middle-gray; }
.light-gray    { background-color: $secondary-light-gray; }
.middle-green  { background-color: $secondary-middle-green; }
.light-green   { background-color: $secondary-light-green; }
.middle-salmon { background-color: $extra-middle-salmon; }
.light-salmon  { background-color: $extra-light-salmon; }
.middle-corn   { background-color: $extra-middle-corn; }
.light-corn    { background-color: $extra-light-corn; }
