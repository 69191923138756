@import "../_styles/variables.module.scss";

.displayed {
    background-color: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 2px;
    border-radius: 1px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.hidden {
    display: none;
}