  @import '../../new-lib/_styles/index.module.scss';

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background;

    @include mobile {
      background-color: $primary-white;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $primary-white;
    width: rem(600px);
    margin-top: rem(56px);
    margin-bottom: rem(108px);
    border-radius: rem(24px);

    @include mobile {
      width: 342px;
      height: 968px;
      margin-top: rem(32px);
      margin-bottom: rem(80px);
    }
  }

  .top-button-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin-top: rem(34px);
    margin-left: rem(24px);
    width: rem(150px);
  }

 