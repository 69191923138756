@import '../_styles/variables.module.scss';

.base {
    border-radius: 37px !important;
    box-sizing: border-box !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    height: 48px;
}

.padding {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 25px !important;
    padding-bottom: 22.8px !important;
}

.noPadding {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.noMargin {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.paddingLoading {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 25px !important;
    padding-bottom: 19px !important;
}

.small {
    height: 40px;
}

.alert {
    border-color: $alert !important;
    color: $alert !important;
}

.primary {
    @extend .base;
    color: #ffffff !important;
    background-color: $primary !important;

    &:hover {
        background-color: $primaryHover !important;
    }

}
.secondary {
    @extend .base;
    border-color: $black;
}

.secondaryAlert {
    @extend .secondary;
    border-color: $alert !important;
}

.tertiary {
    @extend .base;
    border-color: $black !important;
    background-color: #fff !important;
}

.flat {
    @extend .base;
    border-color: transparent !important;
    background-color: transparent !important;
}

.round {
    border-radius: 50px !important;
}

.label {
    font-family: AvenirLTStdHeavy !important;
    font-size: 12px !important;
    font-weight: 900 !important;
    letter-spacing: 0.5px !important;
    line-height: 18px !important;
}
