@import "../_styles/variables.module.scss";

.dialog {
    @media (max-width: $breakpointTablet) {
        top: -20px !important;
        bottom: -20px !important;
        left: -20px !important;
        right: -20px !important;
        border-radius: 5px;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    }
}

.container {
    padding-bottom: 32px;
}

.content {
    @media (min-width: $breakpointTablet) {
        margin-left: 26px;
        margin-right: 26px;
    }
}

.actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}