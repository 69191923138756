@import '../_styles/index.module.scss';

.hero-header {
  width: 100%;
  background-color: $secondary-middle-blue;
  box-sizing: border-box;

  &-with-image {
    @include desktop {
      max-height: rem(408px);
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.hero-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;


  @include mobile {
    padding: rem(40px) rem(24px) rem(32px);
  }

  @include desktop {
    padding: rem(103px) 0;
  }
}

.hero-content {
  @include mobile  { max-width: rem(342px); }
  @include desktop { max-width: rem(456px); }
}

.button-container {
  display: flex;

  @include mobile {
    margin-top: rem(24px);
  }

  @include desktop {
    margin-top: rem(32px);
  }

  &-left   { justify-content: start; }
  &-center { justify-content: center; }
  &-right  { justify-content: end; }
}

.image {
  width: 100%;
  height: rem(408px);

  object-fit: cover;
  object-position: 5%;
}


.color-middle-blue   { background-color: $secondary-middle-blue;  }
.color-light-blue    { background-color: $secondary-light-blue;   }
.color-barbel-blue   { background-color: $secondary-barbel-blue;  }
.color-middle-gray   { background-color: $secondary-middle-gray;  }
.color-light-gray    { background-color: $secondary-light-gray;   }
.color-middle-green  { background-color: $secondary-middle-green; }
.color-light-green   { background-color: $secondary-light-green;  }
.color-middle-salmon { background-color: $extra-middle-salmon;    }
.color-light-salmon  { background-color: $extra-light-salmon;     }
.color-middle-corn   { background-color: $extra-middle-corn;      }
.color-light-corn    { background-color: $extra-light-corn;       }
