@import '../../new-lib/_styles/index.module.scss';

.body {
  background-color: $background;
  display: flex;
  justify-content: center;

  @include mobile {
    background-color: $primary-white;
    padding: rem(32px) 0 rem(80px);
  }

  @include desktop {
    padding: rem(56px) 0 rem(104px);
  }
}

.select-region-container {
  background-color: $primary-white;

  @include mobile { max-width: rem(342px); }
  
  @include desktop {
    max-width: rem(600px);
    border-radius: rem(24px);
    padding: rem(40px) rem(24px);
  }
}

.link-rows-container { margin: rem(32px) 0; }
