@import "../../new-lib/_styles/index.module.scss";

.body {
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preselected-care-unit {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    max-width: rem(744px);
    min-width: rem(342px);
    padding: rem(32px) rem(24px) rem(64px);
    gap: rem(16px);
  }

  @include desktop {
    max-width: rem(744px);
    padding: rem(56px) 0 rem(80px);
    gap: rem(32px);
  }
}

.pre-care-unit-body {
  max-width: rem(360px);
  display: flex;
  flex-direction: column;
  gap: rem(32px);
  margin-top: rem(8px);
}

.contact-health-receptions {
  background-color: $secondary-barbel-blue;
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact-content {
  display: flex;
  flex-direction: column;
  gap: rem(40px);

  @include mobile {
    max-width: rem(744px);
    min-width: rem(342px);
    padding: rem(40px) rem(24px);
  }

  @include desktop {
    max-width: rem(744px);
    padding: rem(56px) 0 rem(48px);
  }
}

.contact-body {
  display: flex;
  justify-content: center;
}

.cta-block-container {
  @include mobile {
    padding: rem(64px) rem(24px) rem(80px);
  }

  @include desktop {
    padding: rem(80px) 0 rem(104px);
  }
}

.cta-block-content {
  display: inline-flex;
  
  @include mobile {
    flex-direction: column;
  }
}