@import '../../_styles//index.module.scss';

.content-container {
  text-align: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  padding: rem(24px) rem(24px) 0;
}

.buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: rem(16px);

  @include mobile {
    padding: rem(16px) rem(24px) rem(24px);
  }
  @include desktop {
    padding: rem(24px);
  }

  &-with-border {
    @extend .buttons-container;
    border-top: rem(1px) solid $secondary-middle-blue;
  }
}
