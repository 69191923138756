@import '../_styles/index.module.scss';

@font-face {
    font-family: 'Proxima Nova';
    src: url("../../assets/fonts/ProximaNovaNative/ProximaNova-Regular.ttf");
    font-weight: 400;
}


.container {
    position: relative;
}

.input-field {
    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;
    
    opacity: 1; // Mandatory for iOS hold-paste
    color: transparent;
    background: transparent;
    background-color: transparent;
    caret-color: transparent;
    border: 0 solid transparent;
    outline: 0 solid transparent;
    box-shadow: none;

    &::selection {
        background: transparent;
    }
}

.slots-container {
    display: flex;
    gap: rem(8px);
    width: fit-content;
}

.slot {    
    display: flex;
    align-items: center;
    justify-content: center;

    border: rem(1px) solid #CCC;
    border-radius: rem(6px);
    font-size: rem(16px);
    line-height: rem(16px);

    transition-property: all;
    transition-duration: 175ms;

    height: rem(40px);
    width: rem(30px);

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;

    &-active {
        box-shadow: 0 0 0 rem(1.5px) $accents-focused;
        border-color: $accents-focused;
    }
}

.caret {
    height: rem(18px);
    width: rem(1px);
    background-color: black;
    display: inline-block;
    animation: caret-blink 1.25s ease-out infinite;

} @keyframes caret-blink {
    0%, 70%, 100% { opacity: 1; }
    20%, 50% { opacity: 0; }
}
