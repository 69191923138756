@import '../_styles/index.module.scss';

.link-row {
  cursor: pointer;
  background-color: $primary-white;
  text-decoration: none;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 rem(1px) 0 $secondary-middle-blue,
              0 rem(-1px)  0 $secondary-middle-blue;

  @include mobile {
    width: rem(342px);
    min-height: rem(56px);
    padding: rem(16px);
  }
  @include desktop {
    width: rem(552px);
    min-height: rem(64px);
    padding: rem(16px) rem(16px) rem(16px) rem(24px);
  }

  &:hover { background-color: $secondary-light-blue; }
}

.left-icon  { 
  @include mobile  { margin-right: rem(12px); }
  @include desktop { margin-right: rem(20px); }
}

.right-icon { margin-left: auto; }

.text-container { 
  @include mobile  { margin-right: rem(12px); }
  @include desktop { margin-right: rem(20px); }
}

.labels {
  display: flex;
  flex-direction: column;
  @include mobile { gap: rem(4px); }
}