@import "../../../lib/_styles/variables.module.scss";
@import '../../../new-lib/_styles/index.module.scss';

.root {
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  background-color: $secondary-light-gray;
  
  @include mobile {
    padding: rem(36px) rem(24px) rem(112px) rem(24px);
  }

  @include desktop {
    padding: rem(60px) rem(24px) rem(60px) rem(24px);
  }
  
}

.link-container {
  @include mobile {
    width: rem(342px);
  }

  @include desktop {
    width: rem(416px)
  }
  padding-bottom: rem(20px);
}

.back-to-link {
  display: flex;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  gap: rem(20px);
}


.card {
  display: flex;
  flex-direction: column;
  border: solid 1px;
  border-radius: 24px;
  border-color: #fff;
  background-color: white;
  
  
  @include mobile {
    min-height: rem(340px);
    width: rem(326px);
    padding: rem(24px) rem(16px) rem(24px) rem(16px);
  }

  @include desktop {
    padding: rem(32px) rem(24px) rem(32px) rem(24px);
    width: rem(384px); 

  }
}

.loading-container {
  @extend .card;


}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

