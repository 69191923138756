@import "../_styles/variables.module.scss";

.h1 {
    color: $black;
    font-family: AvenirLTStdHeavy;
    font-weight: 900;
    letter-spacing: 0;

    @media (min-width: $breakpointTablet) {
        font-size: 30px;
        line-height: 36px;
        margin-top: 50px;
        margin-bottom: 30px;

    }

    @media (max-width: $breakpointTablet - 0.001) {
        font-size: 22px;
        line-height: 28px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}

.h2 {
    color: $black;
    font-family: AvenirLTStdHeavy;
    font-weight: 900;
    letter-spacing: 0;

    @media (min-width: $breakpointTablet) {
        font-size: 22px;
        line-height: 28px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    @media (max-width: $breakpointTablet - 1) {
        font-size: 18px;
        line-height: 26px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.h3 {
    color: $black;
    font-family: AvenirLTStdHeavy;
    font-weight: 900;
    letter-spacing: 0;

    @media (min-width: $breakpointTablet) {
        font-size: 18px;
        line-height: 26px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    @media (max-width: $breakpointTablet - 1) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}


.p {
    color: $black;
    font-family: AvenirLTStdBook;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0px;
    margin-bottom: 20px;
    text-transform: capitalize;
}


.pSM {
    color: $black;
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0px;
    margin-bottom: 20px;
}

.pXS {
    color: $black;
    font-family: AvenirLTStdBook;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 14px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.bold {
    font-weight: 900;
}

.capitalize {
    text-transform: capitalize;
}