@import "../../lib/_styles/variables.module.scss";

.root {
  background-color: $grayBright;
  padding-top: 1.5vh;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  padding-bottom: 5vh;
  flex-grow: 1;
  flex: 1;
  height: 100%;
}

.paper {
  background-color: $white;
  padding: 1vh 5vw;
}

.loader {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: left;
  border-radius: 5px;
  overflow: auto;

  top: 0;
  left: 0;
  background-color: $white;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 33;
}

.body {
  flex: 1;
  word-break: keep-all;
  height: 100%;

  background-color: $gray;

  @media only screen and (max-height: 780px) {
    height: calc(100vh + 101px);
  }

  @media only screen and (max-height: 590px) {
    height: calc(100vh + 100px);
  }

  .button {
    margin: 1.5rem 0;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content: space-evenly;
  }
}
